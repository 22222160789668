<template>
  <v-card elevation="0" :loading="loading">
    <v-card-title class="grey lighten-4 body-2 font-weight-bold" style="color: rgba(0,0,0,.54);height: 56px;">
      {{ $t("dashboard.menu_of_day") }}
    </v-card-title>
    <template v-if="loading || !menu">
      <v-card-text class="pa-2">
        <p v-if="loading" class="text-center ma-0">
          {{ $t("global.loading_message") }}
        </p>
        <p v-else-if="!menu" class="text-center ma-0">
          {{ $vuetify.lang.t("$vuetify.noDataText") }}
        </p>
      </v-card-text>
    </template>
    <template v-else-if="menu">
      <v-card-title class="grey lighten-5 px-0 py-0">
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar :color="menu.color" size="35" class="mr-2">
              <span class="white--text font-weight-bold">
                {{ menu.name.substring(0, 2).toUpperCase() }}
              </span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subheading"> {{ menu.name }}</v-list-item-title>
            <v-list-item-subtitle class="caption"> {{ $helpers.formatDate(menu.date) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
      <v-card-text style="max-height: 215px; overflow-x: hidden">
        <ul>
          <li v-for="(item, index) in menu.items" :key="index">{{ item }}</li>
        </ul>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-07 10:52:26
   * @modify date 2021-01-07 10:52:26
   * @desc Menu
   */
  import { MENU } from "../query";

  export default {
    name: "Menu",
    data: vm => ({
      menu: null,
      loading: false
    }),
    methods: {
      fetchMenu() {
        const options = {
          query: MENU,
          fetchPolicy: "no-cache",
          variables: {
            criteria: {
              beginDate: this.$moment().format("YYYY-MM-DD"),
              endDate: this.$moment().format("YYYY-MM-DD")
            }
          }
        };
        this.loading = true;
        this.$apollo
          .query(options)
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.menu = data.filterMenus.length ? data.filterMenus[0] : null;
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchMenu();
    }
  };
</script>

<style></style>
